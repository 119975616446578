import React from "react";
import html from "../asset/Skills/html.png";
import css from "../asset/Skills/css.png";
import js from "../asset/Skills/js.png";
import Bootstrap from "../asset/Skills/Bootstrap.png";
import tailwind from "../asset/Skills/tailwindcss.png";
import react from "../asset/Skills/reactjs.png";
import ex from "../asset/Skills/express.png";
import mdb from "../asset/Skills/mongodb.png";
import node from "../asset/Skills/nodejs.png";
import chakra from "../asset/Skills/chakra.svg";
import git from "../asset/Skills/github.svg";
import postman from "../asset/Skills/getpostman.svg";

const skills = [
  { name: "HTML", image: html },
  { name: "JavaScript", image: js },
  { name: "CSS", image: css },
  { name: "Tailwind CSS", image: tailwind },
  { name: "Bootstrap", image: Bootstrap },
  { name: "Github", image: git },
  { name: "React.js", image: react },
  { name: "Chakra UI", image: chakra },
  { name: "Express.js", image: ex },
  { name: "Node.js", image: node },
  { name: "MongoDB", image: mdb },
  { name: "Postman", image: postman },
];

const Skill = () => {
  return (
    <div className="container mx-auto flex flex-col items-center justify-center py-6 bg-gray-900 max-w-screen-2xl">
      <h1
        className="flex justify-center items-center text-white font-black text-5xl py-2 mb-4"
        style={{
          background: "#5BADFF",
          backgroundImage: "linear-gradient(to bottom, #5BADFF 39%, #1373D1 85%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        Tech Stack
      </h1>

      <div className="flex justify-center mb-6">
        <div className="grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-6 gap-6">
          {skills.map((skill, index) => (
            <div
              key={index}
              className="flex justify-center items-center p-2 hover:scale-105 transition-transform duration-200"
            >
              <img
                src={skill.image}
                alt={skill.name}
                className="w-16 h-16 sm:w-20 sm:h-20 lg:w-24 lg:h-24 object-contain"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skill;
