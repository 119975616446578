import React from "react";
const certification = [
  {
    title: "Open-Source Certification",
    provider: "Hacktoberfest",
    period: "Issued Oct 2023",
  },
  {
    title: "Postman API Fundamentals Student Expert Certification",
    provider: "Postman",
    period: "Issued Jul 2023",
  },
  {
    title: "Prompt Engineering Certification",
    provider: "LinuxWorld Informatics Pvt Ltd",
    period: "Issued Jul 2023",
  },
  {
    title: "Figma UI UX Design Essentials Certification",
    provider: "Udemy",
    period: "Issued Jun 2023",
  },
];

const Certification = () => {
  return (
    <>
      <style>
        {`
            @media screen and (max-width: 768px) {
              .custom-card {
                width: 150px;
              }
            }
          `}
      </style>

      <div
        id="certification"
        className="container mx-auto items-center p-4 bg-gray-900 max-w-screen-2xl"
      >
         <h1
          className="flex justify-center items-center text-white font-black text-5xl py-2 mb-4"
          style={{
            background: "#5BADFF",
            backgroundImage:
              "linear-gradient(to bottom, #5BADFF 39%, #1373D1 85%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Certification
        </h1>

        {certification.map((certification, index) => (
          <div
            key={index}
            className="container mx-auto lg:max-w-3xl md:max-w-2xl sm:max-w-sm  rounded-lg overflow-hidden mb-4 "
          >
            <div className="p-4 lg:flex lg:justify-between md:flex md:justify-between">
              <p className="lg:text-xl sm:text-lg font-bold text-white">
                {certification.title}
              </p>
              <p className="text-gray-400">{certification.period}</p>
            </div>
            <p className="px-4 pb-2 text-gray-400">{certification.provider}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default Certification;
