import React from 'react';
import { HashRouter as Router } from 'react-router-dom'; // Import HashRouter
import Certification from './component/Certification';
import Contact from './component/Contact';
import Experience from './component/Experience';
import Footer from './component/Footer';
import Header from './component/Header';
import Hero from './component/Hero';
import Projects from './component/Projects';
import Skill from './component/Skill';
import Home from './component/Home';

function App() {
  return (
    <Router>
      <>
        <Home />
        <Header />
        <Hero />
        <Skill />
        <Projects />
        <Experience />
        <Certification />
        <Contact />
        <Footer />
      </>
    </Router>
  );
}

export default App;
