import React from "react";

const experiences = [
  {
    jobRole: "UI/UX Designer",
    period: "Nov 2022 - Jan 2023",
    description:
      "As a part-time UI/UX Designer at Trialshoppy from November 2022 to January 2023, I focused on crafting user interface designs and graphics remotely."
  },
  {
    jobRole: "UI/UX Designer",
    period: "Jun 2023 - Jul 2023",
    description:
      "As an intern at Trialshopy based in Patna, Bihar, India, I remotely contributed to user research and visual design from Jaipur. During this period, I gained valuable experience in enhancing user experiences through thoughtful design choices."
  },
  {
    jobRole: "Web Developer",
    period: "Nov 2022 - Dec 2022",
    description:
      "During my internship at TXON from November 2022 to December 2022, I worked as a Web Developer in Chandigarh, India."
  },
];

const Experience = () => {
  return (
    <>
      <style>
        {`
            @media screen and (max-width: 768px) {
              .custom-card {
                width: 150px;
              }
            }
          `}
      </style>

      <div id="experience" className="container mx-auto items-center p-4 bg-gray-900 max-w-screen-2xl">
        <h1
          className="flex justify-center items-center text-white font-black text-5xl py-2 mb-4"
          style={{
            background: "#5BADFF",
            backgroundImage:
              "linear-gradient(to bottom, #5BADFF 39%, #1373D1 85%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Experience
        </h1>

        {experiences.map((experience, index) => (
          <div key={index} className="container mx-auto lg:max-w-3xl md:max-w-2xl sm:max-w-sm  rounded-lg overflow-hidden mb-4 ">
            <div className="p-4 lg:flex lg:justify-between md:flex md:justify-between">
              <p className="lg:text-2xl sm:text-xl font-bold text-white">
                {experience.jobRole}
              </p>
              <p className="text-gray-400">{experience.period}</p>
            </div>
            <p className="px-4 pb-2 text-gray-400">
              {experience.description}
            </p>
          </div>
        ))}
      </div>
    </>
  );
};

export default Experience;
