import React from "react";
import Avtar from "../asset/avatar.svg";
import resume from "../asset/resume/resume.pdf";
const ContactUrl = "https://www.linkedin.com/in/mohammad-shoaib-0a8298223/";

const Hero = () => {
  return (
    <>
      <div
        id="about"
        className="container mx-auto flex justify-center items-center pt-10 text-center bg-gray-900 max-w-screen-2xl"
      >
        <div className="cont flex justify-center items-center flex-col">
          <img className="w-40" src={Avtar} alt="Avatar" />
          <div className="text-white pt-4 font-black md:text-3xl  ">
            I am
            <span
              className="ml-2 pt-4"
              style={{
                background:
                  "radial-gradient(circle farthest-corner at center center, #FF8660 32%, #9A33FF 83%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Mohammad Shoaib
            </span>
          </div>
          <p className="py-2 text-lg text-gray-400">
            FullStack Developer | UI/UX Designer <br />
            Open Source Contributor | DSA Enthusiast
          </p>

          <div className="btn flex justify-center my-4">
            <a
              href={ContactUrl}
              target="_blank"
              rel="noopener noreferrer"
              class="cursor-pointer group relative flex gap-1.5 px-4 py-2 bg-white text-[#000000] rounded-3xl hover:bg-opacity-70 transition font-semibold shadow-md  mr-1"
            >
              Get in Touch
            </a>

            <a
              href={resume}
              download
              target="_blank"
              rel="noreferrer"
              class="ml-1 cursor-pointer group relative flex gap-1.5 px-4 py-2 border text-white border-white rounded-3xl hover:bg-opacity-70 hover:text-gray-300 transition duration-200 ease-in-out font-semibold shadow-md"
            >
              Download CV
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
