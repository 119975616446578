import React from "react";
import styles from "./Home.module.css";
import arrow from "../asset/arrow-right.svg";

const Home = () => {
  return (
    <>
      <div
        id="home"
        className="container flex items-center  max-w-screen-2xl h-screen"
      >
        <div
          className={`${styles.magicpattern}  text-white flex flex-col justify-center items-center h-full`}
        >
          <div className="text-center lg:text-4xl sm:text-3xl font-normal  p-2 ">
            Hello, I'm Mohammad Shoaib <br />a full stack developer
          </div>

          <a
            href="#navbar"
            className="ml-1 mt-3  lg:text-xl sm:text-sm cursor-pointer group relative flex gap-1.5 px-4 py-2 border text-white border-white rounded-3xl hover:bg-opacity-70 hover:text-gray-300 transition duration-200 ease-in-out font-semibold shadow-md"
          >
            View My Work &nbsp;
            <img
              className={`transition-transform duration-200 transform group-hover:rotate-90 ${styles.glow}`}
              src={arrow}
              alt="arrow"
            />
          </a>
        </div>
      </div>
    </>
  );
};

export default Home;
