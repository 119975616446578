import React from "react";
import insta from "../asset/instagram.svg";
import Git from "../asset/Skills/github.svg";
import linkedin from "../asset/linkedin.svg";

const socialMediaLinks = [
  { platform: "Instagram", icon: insta, link: "https://www.instagram.com/shoaib_xaif" },
  { platform: "GitHub", icon: Git, link: "https://github.com/Shoaibxaif" },
  { platform: "LinkedIn", icon: linkedin, link: "https://www.linkedin.com/in/mohammad-shoaib-0a8298223/" }
];

const Footer = () => {
  return (
    <>
      <hr className="border-gray-100 w-full" />
      <div className="mx-auto bg-gray-800 text-white pb-4 flex flex-col items-center">
        <div className="mt-4">&copy; 2024 Mohammadshoaib. All rights reserved.</div>
        
        <div className="flex mt-4">
          {socialMediaLinks.map((link, index) => (
            <a key={index} href={link.link} target="_blank" rel="noopener noreferrer" className="mr-4">
              <img className="w-5" src={link.icon} alt={link.platform} />
            </a>
          ))}
        </div>
      </div>
    </>
  );
};

export default Footer;
