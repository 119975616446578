import React, { useState } from "react";
import pro0 from "../asset/Projects_Thumbnail/socialmediaApp.svg";
import pro1 from "../asset/Projects_Thumbnail/cryptoinsight.svg";
import pro2 from "../asset/Projects_Thumbnail/videohub.svg";
import pro3 from "../asset/Projects_Thumbnail/thala.svg";
import pro4 from "../asset/Projects_Thumbnail/wordify.svg";
import pro5 from "../asset/Projects_Thumbnail/NewsApp.svg";
import pro6 from "../asset/Projects_Thumbnail/triphopper.svg";
import pro7 from "../asset/Projects_Thumbnail/instaprojects.svg";
import pro8 from "../asset/Projects_Thumbnail/maadv.svg";
import pro9 from "../asset/Projects_Thumbnail/expense_tracker.svg";
import pro10 from "../asset/Projects_Thumbnail/wed_inv.svg";

import "./Projects.css";

const Projects = () => {
  const [activeCategory, setActiveCategory] = useState("all");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const projects = [
    {
      id: 0,
      title: "Blog-App",
      description:
        "Built a secure blog platform with Node.js, Express, MongoDB, and EJS, allowing users to write, edit, and like posts seamlessly.",
      category: "backend",
      image: pro0,
      url: "https://socialmediaapp-jm0j.onrender.com/",
      sourceCode: "https://github.com/Shoaibxaif/socialmediaapp",
    },
    {
      id: 1,
      title: "CryptoInsight",
      description:
        "A React and Chakra UI app that provides real-time cryptocurrency values in INR, helping users make informed market decisions.",
      category: "frontend",
      image: pro1,
      url: "https://cryptoinsight-sigma.vercel.app/",
      sourceCode: "https://github.com/Shoaibxaif/coinapp",
    },
    {
      id: 2,
      title: "VideoHub",
      description:
        "Developed a video streaming app using React and Chakra UI, offering a seamless experience similar to popular video platforms.",
      category: "frontend",
      image: pro2,
      url: "https://videohub-zeta.vercel.app/",
      sourceCode: "https://github.com/Shoaibxaif/VideoHub",
    },
    {
      id: 3,
      title: "Thala For A Reason",
      description:
        "A tribute to MS Dhoni, playing background music for strings/numbers with a sum/length of 7, celebrating his legacy.",
      category: "frontend",
      image: pro3,
      url: "https://thalaforareason07.vercel.app/",
      sourceCode: "https://github.com/Shoaibxaif/thalaforareason",
    },
    {
      id: 4,
      title: "Wordify",
      description:
        "React & Bootstrap-based app for text modification, allowing versatile formatting and text manipulation on a single platform.",
      category: "frontend",
      image: pro4,
      url: "https://wordify-swart.vercel.app/",
      sourceCode: "https://github.com/Shoaibxaif/Wordify",
    },
    {
      id: 5,
      title: "NewsApp",
      description:
        "A React and Bootstrap-based app that delivers real-time news updates through API integration for a smooth browsing experience.",
      category: "frontend",
      image: pro5,
      url: "https://github.com/Shoaibxaif/newsApp",
      sourceCode: "https://github.com/Shoaibxaif/newsApp",
    },
    {
      id: 6,
      title: "TripHopper",
      description:
        "Explore TripHopper, a sleek UI/UX ride app concept designed in Figma, offering an intuitive and futuristic user interface.",
      category: "tools",
      image: pro6,
      url: "https://www.figma.com/proto/mjxhhnaLC7f3jBLaLOvSsb/Ride-App",
      sourceCode: "https://www.figma.com/file/mjxhhnaLC7f3jBLaLOvSsb/Ride-App",
    },
    {
      id: 7,
      title: "InstaProjects",
      description:
        "Created a platform for academic assignments and presentations with a focus on timely delivery, affordability, and user satisfaction.",
      category: "freelance",
      image: pro7,
      url: "https://instaprojects.vercel.app/",
      sourceCode: "confidential",
    },
    {
      id: 8,
      title: "M.A. Advertiser",
      description:
        "Developed a fully customized portfolio website for MA Advertiser, utilizing React, Tailwind CSS, and npm packages for enhanced UX.",
      category: "freelance",
      image: pro8,
      url: "https://ma-advertiser.vercel.app/",
      sourceCode: "confidential",
    },
    {
      id: 9,
      title: "Expense Tracker",
      description:
        "Track expenses efficiently with this fullstack app built using the MERN stack, offering smooth functionality and real-time updates.",
      category: "fullstack",
      image: pro9,
      url: "https://expense-tracker-wheat-eta.vercel.app/",
      sourceCode: "https://github.com/Shoaibxaif/Expence-Tracker",
    },
    {
      id: 10,
      title: "Wedding Invitation",
      description:
        "Crafted a unique and digital wedding invitation with a beautifully designed UI, creating an unforgettable experience for couples.",
      category: "freelance",
      image: pro10,
      url: "https://arshadwedsfaima.vercel.app/",
      sourceCode: "confidential",
    },
  ];

  const filteredProjects =
    activeCategory === "all"
      ? projects
      : projects.filter((project) => project.category === activeCategory);

  return (
    <section className="w-full py-8 bg-[#111827]">
      <div className="container mx-auto px-4 md:px-6">
        <div className="text-center">
          <h1
            className="flex justify-center items-center text-white font-black text-5xl py-2 mb-4"
            style={{
              background: "#5BADFF",
              backgroundImage:
                "linear-gradient(to bottom, #5BADFF 39%, #1373D1 85%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Projects
          </h1>
          <p className="text-[#F5F5F5] mt-4 max-w-4xl mx-auto">
            Check out some of my recent work across various categories.
          </p>
        </div>

        <div className="mt-10 flex flex-wrap justify-center gap-3">
          {[
            "all",
            "frontend",
            "backend",
            "fullstack",
            "freelance",
            "tools",
          ].map((category) => (
            <button
              key={category}
              onClick={() => setActiveCategory(category)}
              className={`${
                activeCategory === category
                  ? "bg-[#1F2937] border-2"
                  : "bg-[#141414] border-2"
              } px-4 py-2 rounded-full text-[#F5F5F5] transition-transform duration-200 hover:scale-105`}
            >
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </button>
          ))}
        </div>

        <div className="mt-10 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {filteredProjects.map((project) => (
            <div
              key={project.id}
              className="relative flex flex-col bg-[#1F2937] rounded-lg border-2 border-[#1F2937] transform transition-all duration-200 hover:scale-105 hover:shadow-lg"
            >
              <img
                src={project.image}
                alt={project.title}
                className="h-60 w-full object-cover rounded-t-lg"
              />
              <div className="p-4">
                <h3 className="text-2xl font-bold text-[#F5F5F5] glow-effect ">
                  {project.title}
                </h3>

                <p className="text-sm text-[#ffffff] mt-2">
                  {project.description}
                </p>
                <div className="mt-4 flex gap-2">
                  <a
                    href={project.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-white text-black px-4 py-1 rounded shadow"
                  >
                    Live Demo
                  </a>
                  {project.sourceCode === "confidential" ? (
                    <button
                      onClick={openModal}
                      className="border border-white text-white px-4 py-1 rounded-md shadow"
                    >
                      Source Code
                    </button>
                  ) : (
                    <a
                      href={project.sourceCode}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="border border-white text-white px-4 py-1 rounded shadow"
                    >
                      Source Code
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        {isModalOpen && (
          <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70"
            role="dialog"
            aria-labelledby="modalTitle"
            aria-describedby="modalDescription"
          >
            <div className="bg-[#1F2937] p-6 rounded-lg text-center border border-[#111827] max-w-xs sm:max-w-md">
              <h2 id="modalTitle" className="text-lg font-bold text-white mb-2">
                Confidential Source Code
              </h2>
              <p id="modalDescription" className="text-sm text-gray-300 mb-4">
                Due to confidentiality, the source code for this project is not
                available.
              </p>
              <button
                onClick={() => {
                  closeModal();
                }}
                className="bg-white text-black px-4 py-2 rounded-md shadow transition-transform hover:scale-105"
              >
                OK
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Projects;
